@import '~app/mixins';

.header {
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  padding-bottom: 15px;
  height: 50px;
}

.headerTitle {
  color: $color-blue-dark;
  font-size: 16px;
  line-height: 18px;
  padding-top: 10px;

  @include text-overflow;
}

.buttons {
  margin-right: 25px;
  display: flex;
  align-items: center;

  button {
    margin: 0 10px;
    font-weight: bold;
    font-size: 14px;
  }

  .buttonExport {
    background: none;
    border: none;
    padding: 0;
    margin: 0 15px;

    &:hover {
      opacity: 0.8;
    }
  }

  .iconExport {
    width: 26px;
    height: 24px;
  }
}

.modalBody {
  margin-left: -15px;
  margin-right: 15px;
  width: calc(100% + 30px);
  height: calc(100vh - 300px);
}

.rowPanels {
  display: flex;
  flex-wrap: nowrap;

  > div {
    margin: 0 2px;
  }
}
